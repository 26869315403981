import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography, MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {getString} from '../Utility/getSystemString';
import LockResetIcon from '@mui/icons-material/LockReset';
import {getFormatedDate} from '../Utility/dateUtils';
import {fetchCodeDescriptionMap, getCodeDescription, fetchTenantNames} from '../Utility/getCodeDescription';
import { isValidEmail, isValidPhone, isRequiredFiledIsEmpty, isValidPassword } from "../Utility/validations";
import MessageSection from '../common/message';
import {getUserIdFromToken} from '../Utility/tokenParsar';
import { loadStripe } from "@stripe/stripe-js";



const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const PersonalDetailsPage = () => {
  const [error, setError] = useState(null);  
  const [info, setInfo] = useState(null);  
  const [success, setSuccess] = useState(null); 
  const [user, setUserData] = useState([]); 
  const [userPayment, setUserPaymentData] = useState([]); 
  const [userPaymentHistory, setUserPaymentHistoryData] = useState([]); 
  const navigate = useNavigate();

  const [statuses, setStatus] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [roles, setRoles] = useState([]);
  const [types, setTypes] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null); 
  const [isViewEditModalOpen, setIsViewEditModalOpen] = useState(false); 
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false); 
  const [modelUsername, setModelUsername] = useState('');
  const [modelLogin, setModelLogin] = useState(''); 
  const [modelCreated, setModelCreated] = useState(''); 
  const [modelLastUpdate, setModelLastUpdate] = useState(''); 
  const [modelTenant, setModelTenant] = useState(''); 
  const [modelUserType, setModelUserType] = useState('');
  const [modelRole, setModelRole] = useState('');
  const [modelStatus, setModelStatus] = useState('');
  const [modelLanguage, setModelLanguage] = useState('');
  const [modelCountryCode, setModelCountryCode] = useState('');
  const [modelPhone, setModelPhone] = useState('');
  const [modelPhoto, setModelPhoto] = useState('');
  const [modelPhotoFileName, setModelPhotoFileName] = useState('');
  const [modelPassword1, setModelPassword1] = useState('');
  const [modelPassword2, setModelPassword2] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(false); 
  const [modelError, setModelError] = useState(null);

  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [nameError, setNameError] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState('');
  const [tenantError, setTenantError] = React.useState(false);
  const [tenantErrorMessage, setTenantErrorMessage] = React.useState('');
  const [phoneError, setPhoneError] = React.useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = React.useState('');
  const [languageError, setLanguageError] = React.useState(false);
  const [languageErrorMessage, setLanguageErrorMessage] = React.useState('');
  const [countryCodeError, setCountryCodeError] = React.useState(false);
  const [countryCodeErrorMessage, setCountryCodeErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');

  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
        setModelError(false);
        setError(false);
        setInfo(false);
        setSuccess(false);
        
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; // Get the current page or route
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,
          },
        };
        var userId= getUserIdFromToken();

        const apiUrl = `api/user/users?user_id=${userId}`;
        const response = await axios.get(apiUrl, config);
        setUserData(response.data.users);
        localStorage.setItem('login_user', JSON.stringify(response));


        const apiUrlPayment = `api/payment/get_active_plan?user_id=${userId}`;
        const paymentResponse = await axios.get(apiUrlPayment, config);
        setUserPaymentData(paymentResponse.data.payments);


        const apiUrlPayment1 = `api/payment/read?user_id=${userId}`;
        const paymentHistoryResponse = await axios.get(apiUrlPayment1, config);
        setUserPaymentHistoryData(paymentHistoryResponse.data.payments);

        const rolesData = await fetchCodeDescriptionMap('user_roles');
        setRoles(rolesData);

        const typesData = await fetchCodeDescriptionMap('user_types');
        setTypes(typesData);  

        const statusesData = await fetchCodeDescriptionMap('statuses');
        setStatus(statusesData);

        const countryCodeData = await fetchCodeDescriptionMap('country_codes');
        setCountryCodes(countryCodeData);

        const languageData = await fetchCodeDescriptionMap('user_languages');
        setLanguages(languageData);

        const tenantData = await fetchTenantNames();
        setTenants(tenantData);

      } catch (error) {
          if (error.response && error.response.data && typeof error.response.data.error === 'string') {
            setError(error.response.data.error );
          }
          if(error.status === 401)
            navigate('/logout');
        } finally {
          
        }
    };

    fetchData();
  }, [navigate]); 

  // Handle click for "View" (read-only mode)
  const handleUserViewClick = (user) => {
    setTenantError(false);
    setNameError(false);
    setEmailError(false);
    setPhoneError(false);
    setLanguageError(false);
    setCountryCodeError(false);
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
    setLanguageErrorMessage('');
    setPhoneErrorMessage('');
    setEmailErrorMessage('');
    setNameErrorMessage('');
    setCountryCodeErrorMessage('');
    setSelectedUser(user); // Set the selected user for editing
    setModelUsername(user.username); // Pre-fill username in the form
    setModelLogin(user.login); 
    setModelUserType(user.hUserType); 
    setModelRole(user.hRole);
    setModelStatus(user.hStatus);
    setModelLanguage(user.huser_language);
    setModelCountryCode(user.hCountryCode);
    setModelPhone(user.phone);
    setModelPhotoFileName(user.photo_filename);
    setModelCreated(user.create_date);
    setModelTenant(user.htenant_id);
    setModelLastUpdate(user.last_update_date);
    setIsReadOnly(true);  // Open modal in read-only mode
    setIsViewEditModalOpen(true);  // Open the modal
  };

   // Function to handle Edit button click
   const handleEditClick = (user) => {
    setTenantError(false);
    setNameError(false);
    setEmailError(false);
    setPhoneError(false);
    setLanguageError(false);
    setCountryCodeError(false);
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
    setLanguageErrorMessage('');
    setPhoneErrorMessage('');
    setEmailErrorMessage('');
    setNameErrorMessage('');
    setCountryCodeErrorMessage('');
    setSelectedUser(user); // Set the selected user for editing
    setModelUsername(user.username); // Pre-fill username in the form
    setModelLogin(user.login); 
    setModelUserType(user.hUserType); 
    setModelLanguage(user.huser_language);
    setModelCountryCode(user.hCountryCode);
    setModelPhone(user.phone);
    setModelPhotoFileName(user.photo_filename);
    setModelRole(user.hRole);
    setModelCreated(user.create_date);
    setModelTenant(user.htenant_id);
    setModelLastUpdate(user.last_update_date);
    setModelStatus(user.hStatus);
    setIsReadOnly(false);
    setIsViewEditModalOpen(true); // Open the modal
  };

  // Function to handle Edit button click
  const handleChangePasswordClick = (user) => {
    setPasswordError(false);
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
    setPasswordErrorMessage('')
    setSelectedUser(user); // Set the selected user for editing
    setIsChangePasswordModalOpen(true); // Open the modal
  };


  const validateInputs = () => {
    let isValid = true;
    const name = document.getElementById('name');
    const email = document.getElementById('email');
    const phone = document.getElementById('phone');

    if (!isValidEmail(email)) {
      setEmailError(true);
      setEmailErrorMessage(getString('VMEM'));
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!isValidPhone(phone)) {
      setPhoneError(true);
      setPhoneErrorMessage(getString('VMPH'));
      isValid = false;
    } else {
      setPhoneError(false);
      setPhoneErrorMessage('');
    }

    if (!isRequiredFiledIsEmpty(name)) {
      setNameError(true);
      setNameErrorMessage(getString('EMFV'));
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage('');
    }

    if (!(modelTenant.toString && modelTenant.toString.length > 0) ){
      setTenantError(true);
      setTenantErrorMessage(getString('EMFV'));
      isValid = false;
    } else {
      setTenantError(false);
      setTenantErrorMessage('');
    }
    if (!(modelLanguage.length && modelLanguage.length > 0) ){
      setLanguageError(true);
      setLanguageErrorMessage(getString('EMFV'));
      isValid = false;
    } else {
      setLanguageError(false);
      setLanguageErrorMessage('');
    }

    if (!isRequiredFiledIsEmpty(phone)) {
      setPhoneError(true);
      setPhoneErrorMessage(getString('EMFV'));
      isValid = false;
    } else {
      setPhoneError(false);
      setPhoneErrorMessage('');
    }

    if (!modelCountryCode) {
      setCountryCodeError(true);
      setCountryCodeErrorMessage(getString('EMFV'));
      isValid = false;
    } else {
      setCountryCodeError(false);
      setCountryCodeErrorMessage('');
    }

    return isValid;
  };


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Check if the file is an image
      const fileType = selectedFile.type;
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

      if (validImageTypes.includes(fileType)) {
        setModelPhoto(selectedFile);
      } else {
        alert(getString('UFPE'));
      }
    }
  };

  // Function to handle modal form submission (Save changes)
  const handleSavePassword = async () => {
    const pass1 = document.getElementById('password1');
    const pass2 = document.getElementById('password2');
    var canCloseDialog=true;

    if(pass1.value !== pass2.value)
    {
      setPasswordError(true);
      setPasswordErrorMessage(getString(getString('LPMM')));
      canCloseDialog = false;
      return;
    } else {
      setPasswordError(false);
      setPasswordError('');
    }
    if (!isRequiredFiledIsEmpty(pass1)) {
      setPasswordError(true);
      setPasswordErrorMessage(getString('EMFV'));
      canCloseDialog = false;
      return;
    } else {
      setPasswordError(false);
      setPasswordError('');
    }
    if (!isRequiredFiledIsEmpty(pass2)) {
      setPasswordError(true);
      setPasswordErrorMessage(getString('EMFV'));
      canCloseDialog = false;
      return;
    } else {
      setPasswordError(false);
      setPasswordError('');
    }
    if (!isValidPassword(pass1)) {
      setPasswordError(true);
      setPasswordErrorMessage(getString('VMPS'));
      canCloseDialog = false;
      return;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }
    if (!isValidPassword(pass2)) {
      setPasswordError(true);
      setPasswordErrorMessage(getString('VMPS'));
      canCloseDialog = false;
      return;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    if(canCloseDialog)
    {
      const updatedUser = {
        password: modelPassword1,
      };

      try {
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; 
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Page-Name': currentPage,
          },
        };
         
        canCloseDialog = true;
        const prevData = await axios.patch(`api/user/update/user_id=${selectedUser.user_id}`, updatedUser, config);
        // Update the user data in the state after successful save
        if(prevData.status === 200)
        {
          setSuccess(prevData.data.msg);
          setPasswordError(false);
          setPasswordErrorMessage('');
          setIsChangePasswordModalOpen(false); // Close the modal after saving
          setSelectedUser(null);
        }
                  
      } catch (error) {
        canCloseDialog=false;
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          setError(error.response.data.error );
        }
        if(error.status === 401)
          navigate('/logout');
      }
    }
    
  }

  // Function to handle modal form submission (Save changes)
  const handleSaveChanges = async () => {
    var canCloseDialog=true;
    if(validateInputs())
    {
      // Perform API call or state update to save changes
      const updatedUser = {
        ...selectedUser,
        username: modelUsername,
        login: modelLogin,
        user_type: modelUserType, 
        role: modelRole,
        status: modelStatus,
        user_language: modelLanguage,
        country_code: modelCountryCode,
        phone: modelPhone,
        photo_filename:modelPhotoFileName,
        file: modelPhoto,
        tenant_id: modelTenant,
      };

      try {
        const formData = new FormData();
        formData.append("file", modelPhoto);
        formData.append("username", modelUsername);
        formData.append("user_type", modelUserType);
        formData.append("user_language", modelLanguage);
        formData.append("country_code", modelCountryCode);
        formData.append("phone", modelPhone);
        formData.append("tenant_id", modelTenant);

        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; 
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Page-Name': currentPage,
          },
        };
         
        canCloseDialog = true;
        const prevData = await axios.patch(`api/user/update/user_id=${selectedUser.user_id}`, formData, config);
        // Update the user data in the state after successful save
        if(prevData.status === 200)
          setSuccess(prevData.data.msg);
        setUserData((prevData) =>
        prevData.map((user) =>
            user.user_id === selectedUser.user_id ? updatedUser : user
          )
        );
        
      } catch (error) {
        canCloseDialog=false;
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          setError(error.response.data.error );
        }
        if(error.status === 401)
          navigate('/logout');
      }
     
      if(canCloseDialog)
      {
        setIsViewEditModalOpen(false); // Close the modal after saving
        setSelectedUser(null);
      }
      
    }
  };

  const userColumns = [
    {
      field: 'actions',
      headerName: getString('GLAC'),
      width: 200,
      renderCell: (params) => (
        <>
          {/* View Icon */}
          <IconButton
            onClick={() => handleUserViewClick(params.row)}
            aria-label="view"
            sx={{ color: '#2e97ff' }}
          >
            <VisibilityIcon />
          </IconButton>
          
          {/* Edit Icon */}
          <IconButton
            onClick={() => handleEditClick(params.row)}
            aria-label="edit"
            sx={{ color: '#2e97ff'}}
          >
            <EditIcon />
          </IconButton>

          {/* Change password Icon */}
          <IconButton
            onClick={() => handleChangePasswordClick(params.row)}
            aria-label="edit"
            sx={{ color: '#2e97ff'}}
          >
            <LockResetIcon />
          </IconButton>

        </>
      ),
    },

    { field: 'login', headerName: getString('GLLI'), width: 230, editable: false },
    { field: 'username', headerName: getString('GLNM'), width: 200, editable: false },
    { field: 'status', headerName: getString('GLS'), width: 160, editable: true },
    { field: 'role', headerName: getString('GLRL'), width: 150, editable: false },
    { field: 'user_type', headerName: getString('GLUT'), width: 200, editable: false },
  ];

  const UserRows = user ? user.map((user, index) => ({
    id: user.user_id,                      // A unique ID for each row                     
    login: user.login,              
    username: user.username,            
    status: getCodeDescription(user.status,statuses),
    hStatus: user.status,
    role: getCodeDescription(user.role,roles),   
    hRole: user.role,           
    user_type: getCodeDescription(user.user_type,types), 
    hUserType: user.user_type,    
    huser_language: user.user_language,  
    hCountryCode: user.country_code,
    phone: user.phone,
    photo: user.photo_url,
    photo_filename:user.photo_filename,    
    create_date: getFormatedDate(user.create_date),
    last_update_date: getFormatedDate(user.last_update_date), 
    tenant_id: getCodeDescription(user.tenant_id,tenants),
    htenant_id: user.tenant_id,
    user_id: user.user_id,
  })) : [];

  const paymentColumns = [
    { field: 'amount_total', headerName: getString('LAMT'), width: 300, editable: false },
    { field: 'status', headerName: getString('GLS'), width: 300, editable: true },
    { field: 'currency', headerName: getString('CRCR'), width: 300, editable: false },
    { field: 'create_date', headerName: getString('LPDT'), width: 300, editable: false },
  ];

  const PaymentRows = userPayment ? userPayment.map((payment, index) => ({
    id: payment.session_id,                      // A unique ID for each row                     
    amount_total: payment.amount_total,                         
    currency: payment.currency, 
    status: payment.status,             
    create_date: getFormatedDate(payment.create_date),
    last_update_date: getFormatedDate(payment.last_update_date), 
    tenant_id: getCodeDescription(payment.tenant_id,tenants),
    htenant_id: payment.tenant_id,
    user_id: payment.user_id,
  })) : [];

  const paymentHistoryColumns = [
    { field: 'amount_total', headerName: getString('LAMT'), width: 300, editable: false },
    { field: 'status', headerName: getString('GLS'), width: 300, editable: true },
    { field: 'currency', headerName: getString('CRCR'), width: 300, editable: false },
    { field: 'create_date', headerName: getString('LPDT'), width: 300, editable: false },
  ];

  const PaymentHistoryRows = userPaymentHistory ? userPaymentHistory.map((payment, index) => ({
    id: payment.session_id,                      // A unique ID for each row                     
    amount_total: payment.amount_total,                         
    currency: payment.currency, 
    status: payment.status,             
    create_date: getFormatedDate(payment.create_date),
    last_update_date: getFormatedDate(payment.last_update_date), 
    tenant_id: getCodeDescription(payment.tenant_id,tenants),
    htenant_id: payment.tenant_id,
    user_id: payment.user_id,
  })) : [];



  return (
    <Container>
      <Typography variant="h4" gutterBottom>{getString('PRSC')}</Typography>
      
      <Grid container spacing={3}>

      
        <Grid item xs={12}>
        
          <Box>
            <Typography variant="subtitle1" gutterBottom>{getString('LPRD')}</Typography>
            <DataGrid
              rows={UserRows}
              columns={userColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick

              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold', // Bold font for the header titles
                }
              }}
            />
          </Box>      
        </Grid> 
        {error && <MessageSection error={getString(error)} />}
        {info && <MessageSection info={getString(info)} />}
        {success && <MessageSection success={getString(success)} />}
        
        <Grid item xs={12}>
          <Box>
          <Typography variant="subtitle1" gutterBottom>{getString('LPPD')}</Typography>
            <DataGrid
              rows={PaymentRows}
              columns={paymentColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick

              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold', // Bold font for the header titles
                }
              }}
            />
          </Box>      
        </Grid>  

        <Grid item xs={12}>
          <Box>
          <Typography variant="subtitle1" gutterBottom>{getString('LPPH')}</Typography>
            <DataGrid
              rows={PaymentHistoryRows}
              columns={paymentHistoryColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick

              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold', // Bold font for the header titles
                }
              }}
            />
          </Box>      
        </Grid>      
      </Grid>

      {/* View/ Edit User Modal */}
      <Dialog open={isViewEditModalOpen} onClose={() => setIsViewEditModalOpen(false)}>
      <DialogTitle>{isReadOnly ? getString('USUD') : getString('USEU')}</DialogTitle>
        <DialogContent>
          <TextField required name='tenant' id='tenant' select label={getString('GLTN')} fullWidth margin="normal" value={modelTenant} onChange={(e) => setModelTenant(e.target.value)} error={tenantError} helperText={tenantErrorMessage} color={tenantError ? 'error' : 'primary'} InputProps={{ readOnly: true}}>
            {tenants.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label={getString('GLNM')}
            fullWidth
            name="name"
            id="name"
            margin="normal"
            value={modelUsername}
            required
            onChange={(e) => setModelUsername(e.target.value)}
            InputProps={{ readOnly: isReadOnly,}}
            error={nameError}
            helperText={nameErrorMessage}
            color={nameError ? 'error' : 'primary'}
          />
          <TextField label={getString('GLLI')} name="email" id="email"fullWidth margin="normal" value={modelLogin} onChange={(e) => setModelLogin(e.target.value)} required error={emailError} helperText={emailErrorMessage} color={nameError ? 'error' : 'primary'} />
          
          <TextField select label={getString('GLUT')} fullWidth margin="normal" value={modelUserType} onChange={(e) => setModelUserType(e.target.value)} required  InputProps={{ readOnly: isReadOnly,}}>
            {types.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>
          
          <TextField select label={getString('GLS')} fullWidth  margin="normal" value={modelStatus} onChange={(e) => setModelStatus(e.target.value)} required  InputProps={{ readOnly: isReadOnly,}}>
            {statuses.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField id='language' name='language' select label={getString('GLLN')} fullWidth  margin="normal" value={modelLanguage} onChange={(e) => setModelLanguage(e.target.value)} required error={languageError} helperText={languageErrorMessage} color={languageError ? 'error' : 'primary'} InputProps={{ readOnly: isReadOnly,}}>
            {languages.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField required select label={getString('GLCC')} fullWidth  
            margin="normal" value={modelCountryCode} 
            onChange={(e) => setModelCountryCode(e.target.value)} 
            error={countryCodeError}
            helperText={countryCodeErrorMessage}
            color={countryCodeError ? 'error' : 'primary'}

            InputProps={{ readOnly: isReadOnly,}}>
            {countryCodes.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField required label={getString('GLPH')} fullWidth margin="normal" value={modelPhone} name="phone" id="phone" 
            error={phoneError}
            helperText={phoneErrorMessage}
            color={phoneError ? 'error' : 'primary'}
            onChange={(e) => setModelPhone(e.target.value)} 
            />
          <TextField label={getString('GLPO')} fullWidth margin="normal" value={modelPhotoFileName} disabled='true'/>
          {!isReadOnly && ( <input type="file" onChange={(e) => handleFileChange(e)}  />)}
          
          {isReadOnly && (<TextField label={getString('GLCD')} fullWidth margin="normal" value={modelCreated} />)}
          {isReadOnly && (<TextField label={getString('GLLU')} fullWidth margin="normal" value={modelLastUpdate} />)}  
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsViewEditModalOpen(false)}>{getString('GNCL')}</Button>
          {!isReadOnly && (
            <Button onClick={handleSaveChanges} variant="contained">
              {getString('GLSV')}
            </Button>
          )}
        </DialogActions>
        {modelError && <MessageSection error={getString(modelError)} />}
      </Dialog>

      {/* Update Password User Modal */}
      <Dialog open={isChangePasswordModalOpen} onClose={() => setIsChangePasswordModalOpen(false)}>
      <DialogTitle>{getString('LNPD')}</DialogTitle>
        <DialogContent>
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  flexGrow: 1,
                }}
              >
                  <Grid container spacing={3}>
                    <FormGrid size={{ xs: 12, md: 6 }}>
                      <TextField
                        label={getString('LTPS')}
                        required
                        fullWidth
                        name="password1"
                        placeholder="••••••"
                        type="password"
                        id="password1"
                        autoComplete="new-password"
                        variant="outlined"
                        onChange={(e) => setModelPassword1(e.target.value)}
                        error={passwordError}
                        helperText={passwordErrorMessage}
                        color={passwordError ? 'error' : 'primary'}
                      />
                    </FormGrid>

                    <FormGrid size={{ xs: 12, md: 6 }}>
                      <TextField
                      label={getString('LTRP')}
                        required
                        fullWidth
                        name="password2"
                        placeholder="••••••"
                        type="password"
                        id="password2"
                        autoComplete="repeat-password"
                        variant="outlined"
                        onChange={(e) => setModelPassword2(e.target.value)}
                        error={passwordError}
                        helperText={passwordErrorMessage}
                        color={passwordError ? 'error' : 'primary'}
                      /> 
                    </FormGrid>
                  </Grid>
                </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsChangePasswordModalOpen(false)}>{getString('GNCL')}</Button>
          <Button onClick={handleSavePassword} variant="contained">{getString('GLSV')}</Button>
        </DialogActions>
        {modelError && <MessageSection error={getString(modelError)} />}
      </Dialog>

    </Container>
);
};

export default PersonalDetailsPage;
