import {jwtDecode} from 'jwt-decode';

export const getUserRoleFromToken = () => {
  try {
    const cachedDataString = localStorage.getItem('login_user');
    const cachedData = JSON.parse(cachedDataString);
    if(cachedData)
      return cachedData.data.users[0]['role'];
    else
      return 'ROV';
  } catch (error) {
    console.error("Invalid cache data:", error);
    return null;
  }
};

export const getUserIdFromToken = () => {
  try {
    // Decode the token
    const token = localStorage.getItem('token'); 
    const decodedToken = jwtDecode(token);
    return decodedToken.user_id;
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
};

export const getUserLoginIdFromToken = () => {
  try {
    // Decode the token
    const token = localStorage.getItem('token'); 
    const decodedToken = jwtDecode(token);
    return decodedToken.name;
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
};

export const getTenantIdFromToken = () => {
  try {
    // Decode the token
    const token = localStorage.getItem('token'); 
    const decodedToken = jwtDecode(token);
    return decodedToken.tenant_id;
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
};