import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import { Box, Button, CssBaseline, Container, CircularProgress, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useLocation, useNavigate } from 'react-router-dom';

const QuizQuestSheetPage = () => {

  const navigate = useNavigate();
  const [data, setData] = useState([]);  // State to hold the data from the backend
  const [loading, setLoading] = useState(true);  // State to handle loading
  const [error, setError] = useState(null);  // State to handle errors
  const location = useLocation();
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [timeLeft, setTimeLeft] = useState(null); 

  const queryParams = new URLSearchParams(location.search);
  const qsd = queryParams.get('qsd'); 
  const qd = queryParams.get('qd');
  const duration = parseInt(queryParams.get('time'), 10) * 60;  
  const guesttoken = queryParams.get('guesttoken');

  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        localStorage.setItem("guesttoken", guesttoken);
        const apiUrl = `api/quiz_response/quizform/quiz_id=${qd}`;
        const response = await axios.get(apiUrl, {
          params: {
            quiz_id: qd,
            quiz_sharing_id: qsd,
            guesttoken: guesttoken
          }
        });

        setData(response.data.quiz_forms);  // Set the fetched data to state
      } catch (error) {
        setError(error.response.data.error);
        console.error('Error fetching data: ', error);
      } finally {
        setLoading(false);  // Stop the loading spinner
      }
    };

    fetchData();    
  }, []);

  const handlQuizQuestSheetSubmit = async (event) => {
    if (event) event.preventDefault(); 
    setLoading(true);  
    try {
      const apiUrl = 'api/quiz_response/create';
      const  response_rows = {
                      "quiz_id": qd,
                      "quiz_sharing_id": qsd,
                      "responder_response": selectedAnswers,
                      "guesttoken":guesttoken}
      const response = await axios.post(apiUrl, 
                                        response_rows,
                                        {  headers: { 'Content-Type': 'application/json' } }
                                      ).then(response => {
                                      const quiz_id = response.headers['quiz_id']
                                      // Navigate to the results page with the response data
                                        navigate('/quizresponseend', { state: { data: response.data} });
                                      })
                                      .catch(error => {
                                        console.error('There was an error!', error);
                                      });
    }catch (error) {
      console.error("Invalid data");
    }
    finally {
      setLoading(false); 
    }
  };

  const handleCheckboxChange = (quiz_forms_id, option, isChecked) => {
    setSelectedAnswers((prevAnswers) => {
      const currentSelections = prevAnswers[quiz_forms_id] || [];
      if (isChecked) {
        // Add option if checked
        return { ...prevAnswers, [quiz_forms_id]: [...currentSelections, option] };
      } else {
        // Remove option if unchecked
        return { ...prevAnswers, [quiz_forms_id]: currentSelections.filter(item => item !== option) };
      }
    });
  };

  // Timer logic
  useEffect(() => {
    // Retrieve or set the start time in localStorage
    let savedStartTime = localStorage.getItem('startTime');
    const startTime = savedStartTime ? new Date(savedStartTime) : new Date();
    
    // If there's no saved start time, save the current time as the start time
    if (!savedStartTime) {
      localStorage.setItem('startTime', startTime);
    }
  
    // Calculate the end time based on the duration
    const endTime = new Date(startTime.getTime() + duration * 1000);
  
    const interval = setInterval(() => {
    const currentTime = new Date();
    const timeRemaining = Math.floor((endTime - currentTime) / 1000);

    // If timeRemaining is less than or equal to 0, submit the form and clear the interval
    if (timeRemaining <= 0) {
      clearInterval(interval);
      handlQuizQuestSheetSubmit(); // Submit the form automatically
    } else {
      setTimeLeft(timeRemaining); // Update the remaining time state
    }
    }, 1000);
  
    // Cleanup on unmount
    return () => clearInterval(interval);
  }, [duration]);


  // Calculate time format
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  // Render loading spinner or error message
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const renderOptions = (response, quiz_forms_id) => {
    // Split response string into an array and map to checkboxes
    return response.split('\n').filter(Boolean).map((option, index) => {
      const [label, value] = option.split(') ');
      const isChecked = selectedAnswers[quiz_forms_id]?.includes(label.trim()) || false;
      return (
        <div key={index}>
          <label>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={(e) => handleCheckboxChange(quiz_forms_id, label.trim(), e.target.checked)}
            />
            {option.trim()}
          </label>
        </div>
      );
    });
  };

  return (
    <Container>
      <CssBaseline enableColorScheme />

        <Box sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1000,
          padding: '10px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={3}>
              <Typography variant="h4" gutterBottom>Quiz!</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* Spacer or additional content can go here */}
            </Grid>
            <Grid size={{ xs: 12, md: 3 }}>
              <Typography variant="h6">Time Left: {timeLeft !== null ? formatTime(timeLeft) : 'Loading...'}</Typography>
            </Grid>
          </Grid>  
        </Box>

        <form onSubmit={handlQuizQuestSheetSubmit}>
          <Box
            sx={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              flexGrow: 1,
              marginTop: '20px',
            }}
          >
            <Grid container spacing={3}>
                <Grid size={{ xs: 12 }}>
                  <Box
                    sx={{
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'stretch',
                    }}
                  >
                    {data.map((quiz) => (
                      <Box key={quiz.quiz_forms_id} sx={{ marginBottom: '15px' }}>
                        <Typography variant="h6">
                          {quiz.question_number}. {quiz.question}
                        </Typography>
                        {renderOptions(quiz.response, quiz.quiz_forms_id)}
                      </Box>
                    ))}
                  </Box>
                </Grid>

                <Grid size={{ xs: 12, md: 3 }}>
                  <Box sx={{ textAlign: 'right' }}>
                    <Box sx={{ '& button': { margin: '10px' } }}>
                      {loading ? (<CircularProgress size={24} />) : (
                        <Button
                          variant="contained"
                          size="small"
                          type="submit"
                          color="primary"
                        >
                          Finish Quiz
                        </Button>
                        )
                      }
                    </Box>
                  </Box> 
                </Grid>           
              </Grid>
            </Box>                                  
          </form>
    </Container>
  );
};

export default QuizQuestSheetPage;
