import * as React from 'react';
import axios from "axios";
import { useState } from "react";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

function ForgotPassword({ open, handleClose }) {
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [login, setLogin] = useState("");
  const handleResetPassword = async () => {
    try{
      setEmailError(false);
      setEmailErrorMessage('');
      const response = await axios.post('api/auth/resetpassword', {login});
      handleClose();
    }
    catch (error) {
      if(error.response.data.type === 'login')
      {
        setEmailError(true);
        setEmailErrorMessage(error.response.data.error);
      }

      else if(error.status === 400)
      {
        setEmailError(true);
        setEmailErrorMessage(error.response.data.error);
      }
      else{
        setEmailError(true);
        setEmailErrorMessage('Unknown Error');
      }
    }

  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: async(event) => {
            event.preventDefault();
            handleResetPassword();
          
        },
      }}
    >
      <DialogTitle>Reset password</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '90%' }}
      >
        <DialogContentText>
          Enter your login email address, and we&apos;ll send you new password.
        </DialogContentText>
        <FormControl fullWidth error={emailError}>
        <OutlinedInput
          autoFocus
          required
          margin="dense"
          id="login"
          name="login"
          onChange={(e) => setLogin(e.target.value)}
          label="Email address"
          placeholder="Login Id"
          type="email"
          fullWidth
          color={emailError ? 'error' : 'primary'}
          error={emailError}
          helperText={emailErrorMessage}
        />
        {emailError && <FormHelperText>{emailErrorMessage}</FormHelperText>}
        </FormControl>
        
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleResetPassword}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotPassword;